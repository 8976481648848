import React, { useState } from "react"
import SEO from "../components/seo"

import axios from "axios"
import { useForm } from "react-hook-form"

import Footer from "../components/Footer"
import Menu from "../components/Menu"

import { isAndroid, isIOS, isDesktop } from "react-device-detect"
import { StaticImage } from "gatsby-plugin-image"

const LogYourLitter = () => {
  const { register, handleSubmit } = useForm()

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = ok => {
    setServerState({
      submitting: false,
      status: { ok },
    })
  }

  const handleOnSubmit = data => {
    const formData = new FormData()

    for (const key in data) {
      if (key === "photos") {
        Array.from(data.photos).forEach((file, index) => {
          formData.append("photos[]", file)
        })
      } else {
        formData.append(key, data[key])
      }
    }

    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/24fa3dc7-41af-4628-a37a-7fb67e4fb171",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(r => {
        console.log(r)
        handleServerResponse(true)
      })
      .catch(r => {
        handleServerResponse(false)
      })
  }

  return (
    <>
      <SEO title="CleanUp - Log your litter" />

      <Menu />

      <main class="max-w-2xl mx-auto pt-10 pb-12 px-12 lg:pb-16  bg-white relative">
        <form
          onSubmit={handleSubmit(handleOnSubmit)}
          encType="multipart/form-data"
        >
          <div class="space-y-6">
            <div>
              <h1 class="text-4xl text-center mb-10 leading-6 font-medium text-gray-900">
                Log your litter
              </h1>
              <p class="mt-1 text-md text-gray-500 mb-10">
                You can use the form below to log your litter collection.
              </p>
            </div>

            {serverState?.status?.ok ? (
              <div class="bg-green-600 rounded p-10 text-white text-center">
                <h3 class="text-5xl font-display mb-5 font-bold">Thank you!</h3>
                <p class="font-bold text-lg mb-5">
                  Every bag collected counts towards creating a cleaner,
                  healthier area.
                </p>
                <p>
                  Please do come back and log any more you might collect in the
                  future.
                </p>
              </div>
            ) : (
              <>
                <div>
                  <label
                    for="form_name"
                    class="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div class="mt-1">
                    <input
                      ref={register({ required: true, maxLength: 20 })}
                      type="text"
                      name="name"
                      id="form_name"
                      class="block w-full shadow-sm focus:ring-light-green-500 focus:border-light-green-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div>
                  <label
                    for="form_name"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Email Address
                  </label>
                  <div class="mt-1">
                    <input
                      ref={register()}
                      type="text"
                      name="name"
                      id="form_name"
                      class="block w-full shadow-sm focus:ring-light-green-500 focus:border-light-green-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  <p class="mt-2 text-sm text-gray-500" id="email-description">
                    We'll only ever use your email to let you know if you win a
                    prize.
                  </p>
                </div>

                <div>
                  <label
                    for="form_school"
                    class="block text-sm font-medium text-gray-700"
                  >
                    School
                    <span class="ml-2 mt-2 text-xs font-regular text-gray-500">
                      Optional
                    </span>
                  </label>

                  <div class="mt-1">
                    <input
                      ref={register()}
                      type="text"
                      name="school"
                      id="form_school"
                      class="block w-full shadow-sm focus:ring-light-green-500 focus:border-light-green-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div>
                  <label
                    for="form_scoutGroup"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Scout Group
                    <span class="ml-2 mt-2 text-xs font-regular text-gray-500">
                      Optional
                    </span>
                  </label>
                  <div class="mt-1">
                    <input
                      ref={register()}
                      type="text"
                      name="scoutGroup"
                      id="form_scoutGroup"
                      class="block w-full shadow-sm focus:ring-light-green-500 focus:border-light-green-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div>
                  <label
                    for="form_collectedFrom"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Litter collected from
                  </label>
                  <div class="mt-1">
                    <textarea
                      ref={register()}
                      id="form_collectedFrom"
                      name="collectedFrom"
                      rows="3"
                      class="block w-full shadow-sm focus:ring-light-green-500 focus:border-light-green-500 sm:text-sm border-gray-300 rounded-md"
                    ></textarea>
                  </div>
                </div>

                <div>
                  <label
                    for="form_bagsCollected"
                    class="block text-sm font-medium text-gray-700"
                  >
                    Number of bags collected
                  </label>
                  <input
                    ref={register({
                      required: true,
                      minLength: 1,
                      maxLength: 3,
                    })}
                    type="number"
                    name="bagsCollected"
                    id="form_bagsCollected"
                    class="mt-1 block w-full shadow-sm focus:ring-light-green-500 focus:border-light-green-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div class="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <div class="space-y-1 text-center">
                      <svg
                        class="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      <div class="flex text-sm text-gray-600 text-center">
                        <label
                          for="file-upload"
                          class="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                        >
                          <input
                            ref={register()}
                            class="w-full"
                            type="file"
                            name="photos[]"
                            multiple
                          />
                        </label>
                      </div>
                      <p class="text-xs text-gray-500">
                        PNG, JPG, GIF up to 10MB
                      </p>
                    </div>
                  </div>
                  <div class="w-full mt-5 rounded-md bg-blue-50 p-4 border-blue-200 border-solid border-2 mx-auto w-60">
                    Please only upload photos you are happy for us to use on the
                    website and other marketing!
                  </div>
                </div>

                <div class="flex justify-end">
                  <button
                    type="submit"
                    class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Log your litter!
                  </button>
                </div>
              </>
            )}
          </div>
        </form>
      </main>

      <Footer />
    </>
  )
}

export default LogYourLitter
